import React from 'react';
import ReactDOM from "react-dom";
import { getInputFromDOM, hiddenResourceTypes } from '../../util';
import { MenuExploreWidget } from '../components';


const entries = getInputFromDOM("portal-config")
  .resource_types
  // remove supertypes:
  .filter(item => ! ["publication", "dataset", "book-data"].includes(item.id))
  // remove publications:
  .filter(item => ! item.id.startsWith("publication"))
  // filter out hidden resource types:
  .filter(item => ! hiddenResourceTypes.includes(item.id))
  // ensure fixed order:
  .sort((a, b) => {
    const types = {
      "dataset-open": 0,
      "dataset-marketplace": 1,
      "dataset-funded":2,
      "book-data-print": 3,
      "book-data-database": 4,
      "ml-approach": 5,
      "preproc-method": 6,
      "task": 7,
      "edutrain-module": 8,
    };
    return (types[a.id] - types[b.id]) || 0;
  })
  .map(i => ({
    id: i.id,
    icon: i.icon,
    title: i.subtype_name ? i.subtype_name : i.type_name, 
    queryString: `metadata.resource_type.id:"${i.id}"`,
  }));


ReactDOM.render(
  <React.StrictMode>
    <MenuExploreWidget 
      menuEntries={entries} 
    />
  </React.StrictMode>,
  document.getElementById("frontpage-explore")
);

const $ = window.$;

$(document).ready(function(){

  $('#frontpage-explore .explore-header').slick({
    infinite: false,
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '<button aria-label="Previous" class="ui icon button"><i class="large angle left icon"></i></button>',
    nextArrow: '<button aria-label="Next" class="ui icon button"><i class="large angle right icon"></i></button>',
    respondTo: "slider",
    responsive: [
      // {
      //   breakpoint: 1000,
      //   settings: {
      //     slidesToShow: 4,
      //     slidesToScroll: 3,
      //   }
      // },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 2,
      //   }
      // },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  });

});
